<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>
		<main-content
			v-if="!page.introblock"
			:title="page.title || page.header"
			:content="page.content"
			:intro-image="{
				image: page.introductionImage,
				imageWebp: page.introductionImageWebp,
			}"
		/>

		<intro-block v-if="page.introblock" :item="page.introblock" />

		<section class="vacancy-search">
			<div class="row header-row">
				<vacancy-search
					:categories="vacancyCategories"
					:cities="vacancyCities"
					@update-results="updateResults($event)"
				/>
				<vacancy-blocks :items="queryResult" />
			</div>
		</section>

		<why-join-us :header="defaults[locale].vacancies.header" :items="defaults[locale].vacancies.whyJoinUs" />

		<section v-if="highlightedVacancies.length" class="featured-vacancies-section">
			<div class="row header-row">
				<div class="columns column12 align-center">
					<picture>
						<source :srcset="defaults[locale].vacancies.sectionFeaturedImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].vacancies.sectionFeaturedImage" />
						<img :src="defaults[locale].vacancies.sectionFeaturedImage" class="icon-image" loading="lazy" />
					</picture>
					<h2>{{ defaults[locale].vacancies.sectionFeaturedTitle }}</h2>
				</div>
				<vacancy-blocks :items="highlightedVacancies" />
			</div>
		</section>

		<our-team :item="defaults[locale].vacancies" />

		<contentblocks :items="page.contentblocks" />

		<social-section
			v-if="page && page.showInstagramFeed"
			:image="defaults[locale].homepage.sectionSocialmediaImage"
			:header="defaults[locale].homepage.sectionSocialmediaTitle"
			:snapwidget-id="defaults[locale].website.snapwidgetId"
		/>
		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

useRemoveFormitable();

const selectedCity = ref(null);
const selectedCategory = ref(null);

const { data: vacancies } = await useWebsiteFetch('vacancies', {
	query: { language: locale.value },
	key: `${locale.value}/vacancies`,
});

const highlightedVacancies = vacancies.value.filter((vacancy) => vacancy.showHighlighted);
const vacancyCategories = [
	...new Set(
		vacancies.value.flatMap((vacancy) => (!vacancy.vacanciesCategory ? [] : vacancy.vacanciesCategory.header)),
	),
];
const vacancyCities = [...new Set(vacancies.value.map((vacancy) => vacancy.city))];

const queryResult = computed(() => {
	if (!selectedCity.value && !selectedCategory.value) {
		return [];
	}

	let result = vacancies.value;
	if (selectedCity.value && selectedCity.value !== 'showAll') {
		result = result.filter((item) => item.city === selectedCity.value);
	}
	if (selectedCategory.value && selectedCategory.value !== 'showAll') {
		result = result.filter(
			(item) => item.vacanciesCategory && item.vacanciesCategory.header === selectedCategory.value,
		);
	}

	return result;
});

const updateResults = ($event) => {
	selectedCity.value = $event.city;
	selectedCategory.value = $event.category;
};
</script>

<style lang="scss" scoped>
.vacancy-search {
	padding: 0 0 100px;
}

.featured-vacancies-section {
	background-color: var(--secondary-background-color);
}

.featured-vacancies-section,
.vacancy-search {
	.row {
		max-width: 1420px;

		h2 {
			margin-bottom: 25px;
		}
	}
}

@media (max-width: 880px) {
	.vacancy-search {
		padding: 0 0 50px;
	}
}
</style>
