<template>
	<div class="row">
		<div class="columns column12 align-center">
			<h5>{{ $t('searchVacancy') }}</h5>
			<form>
				<select v-model="query.category">
					<option value="showAll">{{ $t('allJobCategories') }}</option>
					<option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
				</select>
				<select v-model="query.city">
					<option value="showAll">{{ $t('allJobLocations') }}</option>
					<option v-for="city in cities" :key="city" :value="city">{{ city }}</option>
				</select>
				<button class="button button-cta-variant" @click.prevent="$emit('update-results', query)">
					{{ $t('findYourJob') }}
				</button>
			</form>
		</div>
	</div>
</template>

<script setup>
defineProps({
	cities: { type: Array, default: () => [] },
	categories: { type: Array, default: () => [] },
});

defineEmits(['update-results']);

const query = reactive({
	city: 'showAll',
	category: 'showAll',
});
</script>

<style lang="scss" scoped>
.row {
	margin: 0 auto;

	h5 {
		text-transform: uppercase;
		font-size: 28px;
		margin-bottom: 25px;
	}
}

form {
	margin: 0 auto;
	padding: 25px 0;
	max-width: 900px;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-evenly;
	gap: 15px;
	background-color: var(--secondary-background-color);

	select {
		text-transform: uppercase;
		padding: 15px 50px 15px 30px;
		border: 1px solid #ccc;
		font-size: var(--body-font-size);
		font-family: var(--body-font-family);
		font-weight: 500;
		min-width: 300px;
		background: url('~/assets/images/icon-form-dropdown.png') no-repeat transparent;
		background-position: right 15px top 50%;
		appearance: none;
		color: var(--body-color);

		option {
			color: var(--body-color);
		}
	}
}
</style>
